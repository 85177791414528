require("blockmapsfonts");

require("./menu.js");

require("./scroll.js");

require("./menuHeight.js");

require("./sliderFade.js");

require("./eventlist.js");

require("./akkordeon.js");

require("./textReplace.js");

require("./altershinweis.js");

require("./functions.js");

require("./switchBeer.js");

require("./news-akkordeon.js");

require("./copyrightText.js");







const AOS = require("aos");

AOS.init({
    duration: 600,
    easing: 'ease-out-sine',
    startEvent: 'load'
});

document.querySelectorAll("img").forEach(img => {
    img.addEventListener("load", () => {
        AOS.refresh();
    })
})




