const copyrightText = document.querySelector('.footer-block.last');

if (copyrightText) {
    let htmlContent = copyrightText.innerHTML;
    const currentYear = new Date().getFullYear();
    htmlContent = htmlContent.replace(/\d{4}/, currentYear);

    copyrightText.innerHTML = htmlContent;
}



